<script setup lang="ts" generic="T extends DaneCode | Record<string, string>">
import { DaneCode } from '~/lib/models/lupap'
import { cleanCharsFromString } from '~/utils/stringUtils'

const props = defineProps<{
  name: string
  items: T[]
  maxItems: number
  keyValue: keyof T
}>()
const emit = defineEmits(['set'])

const val = ref('')
const inputAutocomplete = ref()
const showedItems = ref<T[]>()
const focusedItem = ref(-1)

const onInput = (ev: InputEvent) => {
  const value = (ev.target as HTMLInputElement).value || ''
  val.value = value
  filter(val.value)
}

// Autocomplete searchElements
const filter = (input: string) => {
  if (input && input.length < 2) {
    showedItems.value = []
    return null
  }
  // 'ca' will match 'CALDAS,CARTAGENA,CÁCERES,CÁCOTA'
  // 'cá' will match 'CÁCERES, CÁCOTA'
  const encodedInput = input.toLowerCase().normalize('NFD')
  const hasSpecialAccentChar = encodedInput.includes('\u0301')
  const regexSearch = new RegExp(
    `^${hasSpecialAccentChar ? encodedInput : cleanCharsFromString(encodedInput, ['\u0301'])}`
  )
  const items = props.items.filter(item => {
    const val = getItemKeyValue(item)
    const encoded = val.toLowerCase().normalize('NFD')
    const transformed = hasSpecialAccentChar ? encoded : cleanCharsFromString(encoded, ['\u0301'])
    const match = transformed.match(regexSearch)
    return !!match
  })
  if (items.length > props.maxItems) {
    showedItems.value = items.slice(0, props.maxItems)
  } else {
    showedItems.value = items
  }
}

const getItemKeyValue = (item: T) => item[props.keyValue] as string

const selectItem = (item: T) => {
  emit('set', item)
}

const focus = (idx: number) => {
  focusedItem.value = idx
}

const handleOnEnter = () => {
  if (!showedItems.value?.length) return null
  const indexTo = focusedItem.value !== -1 ? focusedItem.value : 0
  selectItem(showedItems.value[indexTo])
}

const handleKeyUp = () => {
  if (!showedItems.value?.length) return null
  const newIndex = focusedItem.value - 1
  const maxIndex = newIndex < 0 ? 0 : newIndex
  focus(maxIndex)
}

const handleKeyDown = () => {
  if (!showedItems.value?.length) return null
  const newIndex = focusedItem.value + 1
  const maxIndex = newIndex > showedItems.value.length - 1 ? 0 : newIndex
  focus(maxIndex)
}

const handleTab = (e: KeyboardEvent) => {
  if (e.key === 'Tab') {
    e.preventDefault()
    e.stopPropagation()
    handleKeyDown()
  }
}

onMounted(() => {
  // focus input on mount
  if (inputAutocomplete.value) inputAutocomplete.value.focus()
})
</script>

<template>
  <section>
    <fieldset class="flex w-full flex-col gap-2 self-end">
      <div class="relative flex w-full flex-1 justify-end">
        <!-- Search icon only on search screen -->
        <button class="absolute left-0 top-1/2 ml-2 h-fit -translate-y-1/2 cursor-pointer">
          <img src="/images/icons/search.svg" alt="search" />
        </button>

        <input
          :id="name"
          ref="inputAutocomplete"
          v-model="val"
          :name="name"
          class="h-8 w-full rounded-md bg-mcd-secondaryIvory pl-10 pr-8 placeholder-secondaryDarkGrey outline-none focus:placeholder-opacity-0 md:h-10"
          autocomplete="off"
          @input="($ev: Event) => onInput($ev as InputEvent)"
          @keydown.enter="handleOnEnter"
          @keydown.down="handleKeyDown"
          @keydown.up="handleKeyUp"
          @keydown.tab="e => handleTab(e)"
        />
        <!-- Items absolute dropdown -->
        <ul
          v-if="val"
          class="absolute left-0 top-full z-[11] mt-3 w-full bg-white"
          @mouseleave="focus(-1)"
        >
          <li
            v-for="(item, idx) in showedItems"
            :key="getItemKeyValue(item)"
            class="mb-4 cursor-pointer border-b border-b-mcd-secondaryLightGrey py-4 text-sm font-bold"
            :class="{ 'bg-mcd-secondaryIvory': idx === focusedItem }"
            @click="selectItem(item)"
            @mouseover="focus(idx)"
          >
            {{ getItemKeyValue(item) }}
          </li>
        </ul>
      </div>
    </fieldset>
  </section>
</template>
