import axios from 'axios'
import { BaseService } from '../../../base.service'
import { publicConfig } from '~/config/public'
import logRequests from '~/lib/axiosUtils'
import { lupapApiResponseDto } from '~/lib/models/lupap'
import { Coords } from '~/lib/models/geolocation'

export class LupapService extends BaseService {
  readonly client
  baseUrl: string

  constructor() {
    super()

    this.baseUrl = publicConfig.services.lupap.url

    this.client = axios.create({
      baseURL: this.baseUrl,
    })
    logRequests(`store:restaurant:lupap`, this.client)
  }

  async getCoordsFromAddress({
    webToken,
    city,
    address,
  }: {
    webToken: string
    city: string
    address: string
  }) {
    const { response } = await this.request(
      {
        url: `co/${city}?a=${encodeURIComponent(address)}&key=${webToken}`,
      },
      lupapApiResponseDto
    )
    return response
  }

  async getAddressFromCoords({ webToken, coords }: { webToken: string; coords: Coords }) {
    const { response } = await this.request(
      {
        url: `reverse?lon=${coords.lng}&lat=${coords.lat}&key=${webToken}`,
      },
      lupapApiResponseDto
    )
    return response
  }
}

export const lupapService = new LupapService()
