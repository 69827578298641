import { z } from 'zod'
export interface DaneCode {
  state: string
  city: string
  key: string
}

const lupapGeometryDto = z.object({
  type: z.string(),
  coordinates: z.array(z.number()),
})

const lupapPropertiesDto = z.object({
  commonName: z.string().nullable(),
  country: z.string(),
  address: z.string(),
  city: z.string(),
  postcode: z.string(),
  accuracy: z.string(),
  admin1: z.string(),
  id: z.string().nullable(),
  attribution: z.string(),
  admin2: z.string().nullable(),
  admin3: z.string().nullable(),
  admin4: z.string().nullable(),
  admin5: z.string().nullable(),
})

const lupapResponseDto = z.object({
  type: z.string(),
  geometry: lupapGeometryDto,
  properties: lupapPropertiesDto,
})
export type LupapResponse = z.infer<typeof lupapResponseDto>

export const lupapApiResponseDto = z.object({
  response: lupapResponseDto,
})
